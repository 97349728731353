<template>
  <div id="user">
    <div class="header-wrap">
      <header v-if="accountInfo">
        <div class="headBox">
          <img
            :src="accountInfo.headimg || DEFAULT_IMG"
            :alt="
              accountInfo.liveConfig
                ? accountInfo.liveConfig.name
                : accountInfo.nickname
            "
          />
          <div class="status offline" v-if="appOffline">APP离线</div>
          <div class="status living" v-else-if="liveStatus && liveStatus.value">
            <van-icon name="bar-chart-o" />直播中
          </div>
          <div class="status stop" v-else>未开播</div>
        </div>
        <div class="userInfo" v-if="accountInfo.liveConfig">
          <div class="item">微信昵称：{{ userInfo.name }}</div>
          <div class="item">直播计划：{{ accountInfo.liveConfig.name }}</div>
          <div class="item">抖音号：{{ accountInfo.liveConfig.douyinid }}</div>
          <div class="item">百应ID：{{ accountInfo.liveConfig.baiyingid }}</div>
          <div class="item" v-if="liveStatus && liveStatus.value">
            已开播：{{ liveTimeStr }}
          </div>
        </div>
      </header>
      <div class="btns" v-if="liveStatus && liveStatus.value">
        <van-button
          type="info"
          size="small"
          @click="stopLive"
          color="#fe2c55"
          :disabled="disableStop"
          >{{ !disableStop ? "一键下播" : "下播中..." }}</van-button
        >
        <van-button type="primary" size="small" @click="voiceReponse"
          >语音回复</van-button
        >
      </div>
      <div class="btns" v-else-if="!appOffline">
        <van-button
          type="primary"
          size="small"
          @click="startLive"
          :disabled="disableLiving"
          >{{ !disableLiving ? "一键开播" : "开播中...." }}</van-button
        >
        <van-button
          v-if="false"
          type="primary"
          size="small"
          @click="voiceReponse"
          >语音回复</van-button
        >
      </div>
    </div>

    <section>
      <div class="title"><span class="label"></span>直播间管理</div>
      <van-tabs
        v-model="activeName"
        color="#1989fa"
        title-active-color="#1989fa"
      >
        <van-tab
          v-for="item in tabs"
          :title="item.label"
          :name="item.id"
          :key="item.id"
        >
          <div class="tab-content-wrap">
            <template
              v-if="
                item.type === 'audio' ||
                item.type === 'bgm' ||
                item.type === 'raw_video'
              "
            >
              <AudioConf :source="item" />
            </template>
            <!-- <AutoBan v-else-if="item.type === 'auto_forbid'" :source="item" /> -->
            <AutoReply v-else-if="item.type === 'auto_reply'" :source="item" />
            <Guide v-else-if="item.type === 'guide'" :source="item" />
            <AudioUpload v-else-if="item.type === 'AudioUpload'" />
          </div>
        </van-tab>
      </van-tabs>
    </section>
    <!--语音答复-->
    <van-overlay :show="showVoice" @click="closeVoice" z-index="30">
      <div class="voice-wrap">
        <div class="img-wrap" v-if="startTimeVoice > 0">
          <div class="music">
            <div class="item one"></div>
            <div class="item two"></div>
            <div class="item three"></div>
            <div class="item four"></div>
            <div class="item five"></div>
            <div class="item six"></div>
            <div class="item seven"></div>
          </div>
        </div>
        <button
          class="btn-wrap"
          :class="{ gray: startTimeVoice > 0 }"
          @touchstart.prevent.stop="startListen"
          @touchend.prevent.stop="stopListen"
        >
          长按录制
        </button>
      </div>
    </van-overlay>
    <!--语音发送确认-->
    <van-overlay :show="showVoiceSend" @click.stop="cancelVoice" z-index="40">
      <div class="voice-send-wrap">
        <div class="text">是否发送？</div>
        <div class="btn-wrap">
          <div class="btn cancel" @click.stop="cancelVoice">取消</div>
          <div class="btn play" @click.stop="playVoice">
            {{ !playFlag ? "试听" : "暂停" }}
          </div>
          <div class="btn ok" @click.stop="sendVoice">发送</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template> 

<script>
import { mapActions, mapState } from "vuex";
// import AutoBan from "@/components/AutoBan/index.vue";
import AutoReply from "@/components/AutoReply/index.vue";
import AudioConf from "@/components/AudioConfig/index.vue";
import Guide from "@/components/Guide/index.vue";
import AudioUpload from "@/components/AudioUpload/index.vue";
// const FilterType = ["audio", "bgm"];
// const FilterType = ["audio", "bgm", "auto_forbid", "auto_reply"];
const FilterType = ["audio", "bgm", "auto_reply", "guide", "raw_video"];

export default {
  components: {
    // AutoBan,
    AutoReply,
    Guide,
    AudioUpload,
    AudioConf,
  },
  computed: {
    ...mapState([
      "userInfo",
      "accountInfo",
      "socket",
      "pid",
      "message",
      "DEFAULT_IMG",
      "isWeixin",
      "ossRef",
    ]),
    liveTimeStr() {
      let t = parseInt(this.liveStatus.duration) || 0;
      let str = "";
      let h = Math.floor(t / 3600000);
      if (h) {
        str += `${h}小时 `;
      }
      let m = Math.floor((t % 3600000) / 60000);
      if (m) {
        str += `${m}分钟 `;
      }
      let s = Math.ceil((t % 60000) / 1000);
      if (s) {
        str += `${s}秒 `;
      } else if (!str) {
        str = "0秒";
      }
      return str;
    },
    tabs() {
      let temp = [];
      if (
        this.accountInfo &&
        this.accountInfo.liveConfig &&
        this.accountInfo.liveConfig.item
      ) {
        let items = this.accountInfo.liveConfig.item.filter(
          (item) => FilterType.indexOf(item.type) !== -1
        );
        items.forEach((element) => {
          temp.push(element);
        });
      }
      return temp;
    },
  },
  data() {
    return {
      showQrcode: false,
      showDownLinkDialog: false,
      statusTime: null,
      liveStatus: {
        value: false,
        duration: 0,
        t: Date.now(),
      },
      activeName: "audio",
      checked: false,
      disableStop: false, //禁止下播
      disableLiving: false, //禁止开播
      showVoice: false, //语音回复
      startTimeVoice: 0, // 开始录音时间
      showVoiceSend: false, //发送弹窗
      voiceId: null, //录制的媒体Id
      playFlag: false, //播放标志
      canRecord: false, //记录一下是否有权限访问
      appOffline: false, //app是否离线，根据applivestate 命令判断，10s内没有收到就离线
    };
  },
  created() {
    this.appState = setInterval(() => {
      if (!this.socket || !this.socket.isConnected()) {
        this.$notify({ type: "danger", message: "网络异常，请检查网络" });
        return;
      }
      if (this.liveStatus && Date.now() - this.liveStatus.t > 10000) {
        this.appOffline = true;
      } else {
        this.appOffline = false;
      }
    }, 10e3);
    if (/Android/gi.test(navigator.userAgent)) {
      this.resizeHandle = function () {
        if (
          document.activeElement.tagName == "INPUT" ||
          document.activeElement.tagName == "TEXTAREA"
        ) {
          window.setTimeout(function () {
            document.activeElement.scrollIntoViewIfNeeded();
          }, 0);
        }
      };
      window.addEventListener("resize", this.resizeHandle);
    }
  },
  destroyed() {
    clearTimeout(this.statusTime);
    clearInterval(this.appState);
    this.resizeHandle &&
      window.removeEventListener("resize", this.resizeHandle);
  },
  watch: {
    message: {
      deep: true,
      handler() {
        if (this.message && this.message.event === "liveState") {
          clearTimeout(this.statusTime);
          this.liveStatus = this.message.params;
          this.statusTime = setTimeout(() => {
            this.loading = false;
            this.liveStatus = {
              value: false,
              duration: 0,
              t: Date.now(),
            };
          }, 15e3);
          this.liveStatus.t = Date.now();
        }
      },
    },
    pid() {
      this.liveStatus = {
        value: false,
        duration: 0,
      };
    },
  },
  methods: {
    ...mapActions(["sendCommandToApp", "setVoiceSetting"]),
    async stopLive() {
      this.$dialog.confirm({
        message: "确定立即下播吗？",
        confirmButtonText: "下播",
        cancelButtonText: "取消",
        beforeClose: async (action, done) => {
          if (action === "confirm") {
            let ret = await this.sendCommandToApp({
              command: "stop",
              needBack: true,
            });
            this.disableLiving = false;
            this.disableStop = true;
            setTimeout(() => {
              this.disableStop = false;
            }, 60e3);
            if (ret && ret.value) {
              this.$toast.success("下播成功");
            } else if (ret && ret.message) {
              this.$toast.fail(`下播失败 ${ret.message}`);
            } else {
              this.$toast.fail("下播失败,请联系客服");
            }
          }
          done();
        },
      });
    },
    async startLive() {
      this.$dialog.confirm({
        message: "确定要开播吗？",
        confirmButtonText: "开播",
        cancelButtonText: "取消",
        beforeClose: async (action, done) => {
          if (action === "confirm") {
            this.sendCommandToApp({ command: "start" });
            this.disableStop = false;
            this.disableLiving = true;
            setTimeout(() => {
              this.disableLiving = false;
            }, 60e3);
          }
          done();
        },
      });
    },
    voiceReponse() {
      if (this.isWeixin) {
        this.startTimeVoice = 0;
        let canRecord = localStorage.getItem("XZM-WXVOICE");
        this.showVoice = true;
        if (canRecord && parseInt(canRecord) === 1) {
          return;
        }
        //先申请权限，防止使用的时候，弹出权限弹窗，触发touchend
        wx.startRecord({
          success: () => {
            wx.stopRecord();
            //同意以后，调用
            localStorage.setItem("XZM-WXVOICE", 1);
          },
          cancel: () => {
            this.$dialog.alert({ message: "请同意麦克风访问权限" });
          },
          fail: (e) => {
            console.log(">>>> 录音失败", e);
          },
        });
      }
    },
    startListen(e) {
      console.log(">>> start listen");
      e.preventDefault();
      this.playFlag = false;
      if (this.startTimeVoice > 0) {
        wx.stopRecord();
      }
      if (this.isWeixin) {
        this.canRecord = setTimeout(() => {
          this.startTimeVoice = Date.now();
          wx.ready(() => {
            wx.startRecord({
              success: () => {},
              cancel: () => {
                this.startTimeVoice = 0;
                this.$dialog.alert({ message: "请同意麦克风访问权限" });
              },
              fail: (e) => {
                console.log(">>>> 录音失败", e);
              },
            });
          });
        }, 300);
      }
    },
    stopListen(e) {
      e.preventDefault();
      let now = Date.now();
      clearTimeout(this.canRecord);
      if (this.startTimeVoice > 0 && now - this.startTimeVoice > 300) {
        wx.stopRecord({
          success: (res) => {
            var localId = res.localId;
            this.voiceId = localId;
            this.showVoiceSend = true;
          },
          cancel: () => {
            this.$toast("录音失败,请重录！");
          },
          fail: (e) => {
            console.log(">>>>stopRecord 录音失败", e);
          },
        });
      } else {
        wx.stopRecord();
        //时间太短或者无效就直接停止录音
        this.$toast("录音太短了！");
      }
      this.startTimeVoice = 0;
    },
    closeVoice() {
      this.showVoice = false;
      this.showVoiceSend = false;
      this.voiceId = null;
    },
    cancelVoice() {
      wx.stopVoice({
        localId: this.voiceId,
      });
      this.voiceId = null;
      this.showVoiceSend = false;
    },
    sendVoice() {
      wx.uploadVoice({
        localId: this.voiceId, // 需要上传的音频的本地ID
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: (res) => {
          var serverId = res.serverId; // 返回音频的服务器端ID
          // 发送资源Id给app
          this.sendCommandToApp({
            command: "wxVoice",
            params: { value: serverId },
          });
        },
      });
      this.cancelVoice();
    },
    playVoice() {
      if (this.voiceId) {
        if (this.playFlag) {
          wx.pauseVoice({ localId: this.voiceId });
          this.playFlag = false;
        } else {
          wx.playVoice({ localId: this.voiceId });
          this.playFlag = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss">
</style>