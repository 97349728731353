<template>
  <div class="audio-wrap">
    <div class="item">
      <div class="label">状态</div>
      <div class="value">
        <div class="wrap">
          <van-icon
            name="play-circle"
            v-if="!status"
            @click="statusChange"
            color="#1989fa"
            size="30"
          />
          <van-icon
            name="pause-circle"
            v-else
            @click="statusChange"
            color="#1989fa"
            size="30"
          />
          <div class="progress-wrap">
            <van-progress :percentage="percent" />
            <div class="text">{{ currentStr }}&nbsp;/&nbsp;{{ totalStr }}</div>
            <div class="time-text">更新时间：{{ earliest }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="label">音量</div>
      <div class="value">
        <van-slider v-model="voice" :min="0" :max="100" @change="voiceChange" />
      </div>
    </div>
    <template v-if="source.type === 'audio'">
      <div class="title-item">资源</div>
      <van-cell-group :border="false">
        <van-cell
          v-for="(sitem, index) in resource"
          :key="sitem.id"
          :data-index="index"
        >
          <template #title>
            <div class="source-item">
              <div
                class="audio-wrap"
                :class="{
                  working:
                    fileUpStatus[sitem.id] &&
                    ['pending', 'running'].indexOf(fileUpStatus[sitem.id]) > -1,
                }"
              >
                <audio
                  :src="sitem.url"
                  controls
                  controlsList="nodownload"
                ></audio>
              </div>
              <van-button size="small" type="primary" @click="showUpload(index)"
                >替换</van-button
              >
              <div class="time-text">
                上传时间：{{ timeStr(sitem.updatedAt) }}
              </div>
              <div
                class="time-text remain"
                :class="{
                  timeout:
                    Date.now() >
                    new Date(sitem.updatedAt).getTime() + 2 * 3600 * 24 * 1000,
                }"
              >
                {{ remainTime(sitem.updatedAt) }}
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </template>
    <!--上传文件-->
    <van-popup v-model="showFileUpload" @close="closeFileUpload">
      <div class="upload-wrap">
        <div class="tip">请选择需要上传的文件,总时长需要2小时以上</div>
        <div class="upload-content-wrap">
          <van-uploader
            v-model="fileList"
            multiple
            :after-read="afterRead"
            :before-read="beforeRead"
            accept=".mp3,.wav,.m4a"
          >
          </van-uploader>
        </div>
        <van-divider>总时长：{{ fileTotalTimeStr }}</van-divider>
        <div class="upload-btn">
          <van-button
            :disabled="disableUpload"
            @click.stop.prevent="upload"
            block
            type="info"
            >上传文件</van-button
          >
        </div>
      </div>
    </van-popup>
    <!--loading-->
    <van-overlay :show="showLoading" :z-index="9000">
      <div class="loading-wrapper">
        <van-loading type="spinner" color="#fff"></van-loading>
      </div>
    </van-overlay>
    <!--upload loading-->
    <van-overlay :show="showUpLoading" :z-index="9000">
      <div class="loading-wrapper">
        <van-circle
          v-model="curRate"
          :rate="loadRate"
          :speed="100"
          fill="#fff"
          :text="loadText"
        />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SparkMD5 from "spark-md5";
export default {
  name: "AudioConf",
  props: {
    source: Object,
  },
  data() {
    return {
      status: true,
      voice: 100,
      process: 0,
      resource: [],
      currentTime: 0,
      totalTime: 0,
      showFileUpload: false,
      fileList: [],
      optItemIndex: null,
      showLoading: false, //显示loading 遮罩
      showUpLoading: false, //显示上传文件
      upCount: 0, //目前上传文件的数量
      curRate: 0, //用于动画显示的状态
      loadRate: 0,
      fileUpStatus: {}, //文件上传以后，语音文件合成状态，pending=待上传,running=转码中,finished=转码完成
      fileAudioIds: {}, //正在运行任务的id
    };
  },
  created() {
    if (this.source) {
      this.status = this.source.enable;
      this.voice = this.source.volume * 100;
      this.resource = this.source.source;
      this.fetchUpStatusById();
    }
  },
  computed: {
    ...mapState(["message", "pid", "accountInfo"]),
    percent() {
      if (this.currentTime > 0 && this.totalTime > 0) {
        let d = Math.round((this.currentTime / this.totalTime) * 100);
        return Math.min(d, 100);
      }
      return 0;
    },
    currentStr() {
      let str = "";
      str += `${("00" + Math.floor(this.currentTime / 3600)).substr(-2)}:`;
      str += `${("00" + Math.floor((this.currentTime % 3600) / 60)).substr(
        -2
      )}:`;
      str += `${("00" + Math.floor(this.currentTime % 60)).substr(-2)}`;
      return str;
    },
    totalStr() {
      let str = "";
      str += `${("00" + Math.floor(this.totalTime / 3600)).substr(-2)}:`;
      str += `${("00" + Math.floor((this.totalTime % 3600) / 60)).substr(-2)}:`;
      str += `${("00" + Math.floor(this.totalTime % 60)).substr(-2)}`;
      return str;
    },
    earliest() {
      let data = this.source.source;
      let t = new Date();
      data.forEach((item) => {
        let ta = new Date(item.updatedAt);
        if (ta.getTime() < t.getTime()) {
          t = ta;
        }
      });
      return t.toLocaleString();
    },
    loadText() {
      return `${this.upCount}/${this.fileList.length}`;
    },
    fileTotalTimeStr() {
      let t = 0;
      this.fileList.forEach((item) => {
        if (item.duration) {
          t += item.duration;
        }
      });
      let str = "";
      if (t > 3600) {
        str += `${Math.floor(t / 3600)}小时 `;
      }
      t = t % 3600;
      if (t > 60) {
        str += `${Math.floor(t / 60)} 分 `;
      }
      t = t % 60;
      if (t > 0) {
        str += `${Math.floor(t)}秒`;
      }
      if (!str) {
        return "0";
      }
      return str;
    },
    disableUpload() {
      if (!this.fileList.length) return true;
      let t = 0;
      this.fileList.forEach((item) => {
        if (item.duration) {
          t += item.duration;
        }
      });
      return t < 2 * 3600;
    },
  },
  watch: {
    message: {
      deep: true,
      handler() {
        if (
          this.message &&
          this.message.event === "media" &&
          this.message.params &&
          this.message.params.id === this.source.id
        ) {
          this.currentTime = this.message.params.currentTime;
          this.totalTime = this.message.params.duration;
        }
      },
    },
    pid() {
      if (this.source) {
        this.status = this.source.enable;
        this.voice = this.source.volume * 100;
        this.resource = this.source.source;
        this.fetchUpStatusById();
      }
    },
  },
  destroyed() {
    clearTimeout(this.timeInterval);
    clearTimeout(this.statusInterval);
  },
  methods: {
    ...mapActions(["setConfig", "sendCommandToApp", "getCurrentConfig"]),
    statusChange() {
      this.status = !this.status;
      console.log(">>>> status change ", this.status);
      this.setConfig({
        itemId: this.source.id,
        config: { enable: this.status },
      });
      this.sendCommandToApp({
        command: "mediaUpdate",
        params: {
          value: this.voice / 100,
          enable: this.status,
          id: this.source.id,
        },
      });
    },
    voiceChange() {
      this.setConfig({
        itemId: this.source.id,
        config: { volume: this.voice / 100 },
      });
      this.sendCommandToApp({
        command: "mediaUpdate",
        params: {
          value: this.voice / 100,
          enable: this.status,
          id: this.source.id,
        },
      });
    },
    timeStr(time) {
      let t = new Date(time);
      return t.toLocaleString();
    },
    closeFileUpload() {
      this.showLoading = false;
      this.showUpLoading = false;
      this.optItemIndex = null;
      this.showFileUpload = false;
    },
    showUpload(index) {
      this.optItemIndex = index;
      this.showFileUpload = true;
      this.curRate = 0;
      this.upCount = 0;
      this.loadRate = 0;
      this.fileList = [];
    },
    beforeRead(file) {
      console.log(">>>>beforeRead", file);
      let data = [];
      if (!file.length) {
        data = [file];
      } else {
        data = file;
      }
      let names = {};
      data.forEach((item) => (names[item.name] = 1));
      if (this.fileList.find((item) => names[item.file.name])) {
        this.$toast("文件重复添加,重新选择");
        return false;
      }
      this.showLoading = true;
      return true;
    },
    afterRead(file) {
      console.log(">>>>afterRead", file);
      let data = [];
      if (!file.length) {
        data = [file];
      } else {
        data = file;
      }
      data.forEach((item) => {
        var url = URL.createObjectURL(item.file);
        let content = item.content ;
        if(!content){
          content = [item.file.size,item.file.type].join('')
        }
        let hash = SparkMD5.hash(content);
        this.$set(item, "hash", hash);
        delete item.content;
        var audio = new Audio(url);
        // 元数据已加载
        audio.muted = true;
        //微信浏览器兼容问题，不然无法触发 loadedmetadata
        audio.play().then(() => audio.pause());
        audio.addEventListener("loadedmetadata", () => {
          let duration = audio.duration;
          console.log(">>>>> duration", hash, parseInt(duration));
          this.$set(item, "duration", parseInt(duration)); //单位是秒
        });
        //md5生成
      });
      this.showLoading = false;
    },
    async upload() {
      if (this.showUpLoading) return;
      let optId = this.resource[this.optItemIndex].id;
      if (
        this.fileUpStatus[optId] === "running" ||
        "pending" === this.fileUpStatus[optId]
      ) {
        await this.$dialog.confirm({
          message: "您有合成任务在运行中需要先停止，确定停止吗？",
          confirmButtonText: "终止任务",
        });
        await this.$http.post(
          `/devices/${this.accountInfo.id}/audios/${
            this.fileAudioIds[optId]
          }/stop`
        );
        this.fileUpStatus[optId] = "stop";
      }
      clearTimeout(this.statusInterval);
      this.showUpLoading = true;
      this.fileList.forEach((item) => {
        (item.status = "uploading"), (item.message = "上传中……");
      });
      // md5 判断是否已经上传过
      let ret = await this.$http.post(
        `/devices/${this.accountInfo.id}/audios`,
        {
          audio_md5: this.fileList.map((item) => item.hash),
          item_id: this.source.id,
          source_id: optId,
        }
      );
      let audioId = ret.id;
      let tempObj = {};
      if (ret.uploaded_md5 && ret.uploaded_md5.length) {
        ret.uploaded_md5.forEach((item) => (tempObj[item] = 1));
      }
      for (let i = 0; i < this.fileList.length; i++) {
        try {
          //如果不在已经上传中的文件，就上传
          if (!tempObj[this.fileList[i].hash]) {
            let file = this.fileList[i].file;
            let data = new FormData();
            data.append("audio", file);
            await this.$http.post(
              `/devices/${this.accountInfo.id}/audios/${audioId}/${this.fileList[i].hash}`,
              data,
              { timeout: 0 }
            );
          }
          this.fileList[i].status = "success";
          this.fileList[i].message = "";
          this.upCount++;
          this.loadRate = Math.round(
            (this.upCount / this.fileList.length) * 100
          );
        } catch (e) {
          this.fileList[i].status = "failed";
          this.fileList[i].message = "上传失败";
        }
      }
      this.fileList = this.fileList.filter((item) => item.status === "failed");
      this.status = true;
      if (this.fileList.length === 0) {
        this.fetchVoiceFileStatus(audioId, optId);
        this.closeFileUpload();
      } else {
        this.$toast("部分上传成功");
        this.showUpLoading = false;
        this.upCount = 0;
      }
    },
    remainTime(updTime) {
      let total = 5 * 24 * 3600 * 1000; //5天
      let updT = new Date(updTime);
      let remain = total - (Date.now() - updT.getTime());
      if (remain < 0) {
        return "已过期";
      } else {
        let str = "有效期：";
        let d = Math.floor(remain / (24 * 3600 * 1000));
        if (d > 0) {
          str += `${d}天 `;
        }
        remain = remain % (24 * 3600 * 1000);
        let h = Math.floor(remain / (3600 * 1000));
        if (h > 0) {
          str += `${h}时 `;
        }
        remain = remain % (3600 * 1000);
        let m = Math.floor(remain / (60 * 1000));
        if (m > 0) {
          str += `${m}分`;
        }
        return str;
      }
    },
    async fetchVoiceFileStatus(audioId, itemId) {
      this.$set(this.fileAudioIds, itemId, audioId);
      // try {
        let ret = await this.$http.get(
          `/devices/${this.accountInfo.id}/audios/${audioId}`
        );
        if (ret && ret.status) {
          this.$set(this.fileUpStatus, itemId, ret.status);
          if (
            ret.status !== "finished" &&
            ret.status !== "stop" &&
            ret.status !== "failed"
          ) {
            this.statusInterval = setTimeout(() => {
              this.fetchVoiceFileStatus(audioId, itemId);
            }, 10e3);
          } else {
            let idx = this.resource.findIndex((item) => item.id === itemId);
            console.log('>>>>>status idx',idx,this.resource,this.resource[idx])
            this.resource[idx].url = ret.url;
            this.resource[idx].updatedAt = ret.created_at;
            setTimeout(async () => {
              await this.getCurrentConfig(this.accountInfo);
              let data = this.accountInfo.liveConfig.item.find(
                (item) => item.id === this.source.id
              );
              console.log('>>>>>config ',data,idx,this.resource);
              this.resource[idx] = data.source[idx];
            }, 5000);
          }
        }
      // } catch (e) {
      //   console.log(">>>>fetchVoiceFileStatus error  ", e);
      //   setTimeout(() => {
      //     this.fetchVoiceFileStatus(audioId, itemId);
      //   }, 10e3);
      // }
    },
    async fetchUpStatusById() {
      if (this.source.type !== "audio") return;
      clearTimeout(this.timeInterval);
      let ids = this.resource
        .filter(
          (item) =>
            ["stop", "finished", "failed"].indexOf(
              this.fileUpStatus[item.id]
            ) === -1
        )
        .map((item) => item.id);
      console.log(">>>>idx", ids);
      if (!ids.length) return;
      let ret = await Promise.all(
        ids.map((id) =>
          this.$http.get(`/devices/${this.accountInfo.id}/audio-sources/${id}`)
        )
      );
      console.log(">>> fetchUpStatusById", ret);
      ret.forEach((item, index) => {
        if (item && item.length) {
          this.$set(this.fileUpStatus, ids[index], item[0].status);
          this.$set(this.fileAudioIds, ids[index], item[0].id);
        } else {
          this.$set(this.fileUpStatus, ids[index], "finished");
          this.$delete(this.fileAudioIds, ids[index]);
        }
      });
      this.timeInterval = setTimeout(() => {
        this.fetchUpStatusById();
      }, 5e3);
    },
  },
};
</script>
<style lang="scss" scoped>
.audio-wrap {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: r(40);
    padding-top: r(20);
    &:first-child {
      margin-bottom: r(60);
    }
    .label {
      font-size: r(28);
      margin-right: r(40);
      flex-shrink: 0;
    }

    .value {
      flex: 1;
      :deep .van-slider__button {
        border: 1px solid #1989fa;
      }
    }
  }

  .title-item {
    margin-top: r(80);
    margin-bottom: r(40);
  }

  .source-item {
    margin-bottom: r(20);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .audio-wrap {
      position: relative;
      width: 80%;
      audio {
        display: block;
        width: 100%;
      }
      &.working {
        &::before {
          content: "合成中";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.75);
          z-index: 2;
          width: 100%;
          height: 100%;
          text-align: center;
          font-size: r(20);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .time-text {
      position: absolute;
      left: r(10);
      font-size: r(16);
      color: #a1a1a1;
      height: r(18);
      line-height: r(18);
      bottom: r(-30);
      &.remain {
        left: auto;
        right: 20%;
      }
      &.timeout {
        color: red;
      }
    }
    :deep .van-button {
      flex-shrink: 0;
      margin-left: r(20);
    }
  }

  :deep .van-cell {
    align-items: center;
  }
  .wrap {
    display: flex;
    align-items: center;
    :deep .van-progress {
      flex: 1;
      .van-progress__pivot {
        transition: left 300ms linear;
      }
    }
    .progress-wrap {
      flex: 1;
      margin-left: r(20);
      position: relative;
      .text {
        position: absolute;
        bottom: r(-48);
        right: r(10);
        font-size: r(16);
        color: #a1a1a1;
        height: r(18);
        line-height: r(18);
      }
      .time-text {
        position: absolute;
        bottom: r(-48);
        left: r(10);
        font-size: r(16);
        color: #a1a1a1;
        height: r(18);
        line-height: r(18);
      }
    }
  }

  .upload-wrap {
    width: r(700);
    box-sizing: border-box;
    padding: r(40);
    .tip {
      color: #aaa;
      font-size: r(20);
      line-height: r(30);
      text-align: center;
    }
    .upload-content-wrap {
      margin-top: r(20);
      text-align: center;
    }
    .upload-btn {
      text-align: center;
      margin-top: r(20);
    }
    .file-list {
      .title {
        font-weight: bold;
        font-size: r(30);
        padding: r(20) 0;
      }
      .item {
        padding: r(20) 0;
        audio {
          display: block;
        }
        .time {
          font-size: r(16);
          color: #a1a1a1;
          height: r(18);
          line-height: r(28);
          margin-left: r(20);
          margin-top: r(10);
        }
      }
    }
  }
  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>