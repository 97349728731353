<template>
  <div class="audio-wrap">
    <div class="tip">请选择需要上传的文件，单文件大小不要超过200M</div>
    <div class="upload-wrap">
      <van-uploader
        v-model="fileList"
        multiple
        :after-read="afterRead"
        accept="audio/mp3,audio/wav,audio/mp4"
      >
      </van-uploader>
    </div>
    <div class="upload-btn">
      <van-button
        v-if="fileList.length"
        @click.stop.prevent="upload"
        type="info"
        >上传文件</van-button
      >
    </div>
    <div class="file-list" v-if="list.length">
      <div class="title">文件</div>
      <div class="item" v-for="item in list" :key="item.id">
        <audio
          controls="controls"
          controlsList="nodownload"
          :src="item.uri"
        ></audio>
        <div class="time">
          上传时间:{{ new Date(item.created_at).toLocaleString() }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "AudioUpload",
  data() {
    return {
      fileList: [],
      status: false,
      list: [],
    };
  },
  methods: {
    async fetch() {
      if (!this.accountInfo) return;
      let ret = await this.$http.get(`/devices/${this.accountInfo.id}/audios`);
      console.log(">>>>file ret", ret);
      this.list = ret.data;
    },
    async upload() {
      console.log(">>> upload ", this.fileList);
      if (this.status) return;
      this.fileList.forEach((item) => {
        (item.status = "uploading"), (item.message = "上传中……");
      });
      this.status = true;
      for (let i = 0; i < this.fileList.length; i++) {
        try {
          let file = this.fileList[i].file;
          let data = new FormData();
          data.append("audio", file);
          await this.$http.post(`/devices/${this.accountInfo.id}/audios`, data);
          this.fileList[i].status = "success";
          this.fileList[i].message = "";
        } catch (e) {
          console.log(">>>> 上传文件失败", e);
          this.fileList[i].status = "failed";
          this.fileList[i].message = "上传失败";
        }
      }
      this.fileList = this.fileList.filter((item) => item.status === "failed");
      this.status = true;
      if (this.fileList.length === 0) {
        this.$toast("上传完成");
      } else {
        this.$toast("部分上传成功");
      }
      this.fetch();
    },
    afterRead() {
      console.log(">>>>", arguments, this.fileList);
    },
  },
  computed: {
    ...mapState(["pid", "accountInfo"]),
  },
  watch: {
    pid: {
      immediate: true,
      handler() {
        this.fetch();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@function r($px) {
  @return ($px / 30) + rem;
}
.audio-wrap {
  height: 100%;
  overflow: hidden;
  .tip {
    color: #aaa;
    font-size: r(20);
    text-align: center;
  }
  .upload-wrap {
    margin-top: r(20);
    text-align: center;
  }
  .upload-btn {
    text-align: center;
    margin-top: r(20);
  }
  .file-list {
    .title {
      font-weight: bold;
      font-size: r(30);
      padding: r(20) 0;
    }
    .item {
      padding: r(20) 0;
      audio {
        display: block;
      }
      .time {
        font-size: r(16);
        color: #a1a1a1;
        height: r(18);
        line-height: r(28);
        margin-left: r(20);
        margin-top: r(10);
      }
    }
  }
}
</style>