<template>
  <div class="x-editor" :class="{ 'x-editor-mode-edit': mode, editing }">
    <div v-show="labels.length && (mode == 'edit' || editing)" class="x-editor-menu">
      <img
        v-for="(tagLabel, tagName) in taglist"
        class="x-editor-tag"
        :data-var="tagName"
        :data-label="tagLabel"
        :src="tagLabel"
        :key="tagName"
        @mousedown.prevent="insert(tagName)"
      />
    </div>
    <div
      class="x-editor-paper"
      :class="{ editing: mode == 'edit' || editing ,value:xhtml.length}"
      v-html="xhtml"
      contenteditable="true"
      @keydown.enter.prevent="save"
      @keydown.esc.prevent="cancel"
    ></div>
  </div>
</template>

<script>
/**
 * 使用方式：
 *
 * 双向绑定数据，默认是展现模式，点击内容变成编辑模式
 * <Editor v-model="pattern">
 *
 * 始终是编辑模式
 * <Editor v-model="pattern" mode="edit">
 *
 * 指定标签列表（推荐），要求是object对象，key是模板变量名，value是占位符icon图片地址
 * <Editor v-model="pattern" :labels="['userNick', 'anchorNick']">
 *
 * 指定标签列表（已弃用，但仍然支持），要求是object对象，key是模板变量名，value是占位符icon图片地址
 * <Editor v-model="pattern" :tags="{userNick: '图片url'}">
 */

// 主播昵称
const anchorNick = require('@/assets/x-editor/tag1.png');
// 粉丝昵称
const userNick = require('@/assets/x-editor/tag2.png');
// 礼物
const gift = require('@/assets/x-editor/tag3.png');
// 礼物存量
const walletGift = require('@/assets/x-editor/tag4.png');
// 奖品
const award = require('@/assets/x-editor/tag5.png');
// 陪玩技能
const accompanySkill = require('@/assets/x-editor/tag6.png');
// 贵族等级
const noble = require('@/assets/x-editor/tag7.png');
// 守护等级
const guardLevel = require('@/assets/x-editor/tag8.png');
// 粉丝等级
const badge = require('@/assets/x-editor/tag10.png');
// 马甲格式
const nickPrefix = require('@/assets/x-editor/tag9.png');
// 陪玩技能
const accompanyTarget = require('@/assets/x-editor/tag11.png');
// 消费档暗号
const consumeSignal = require('@/assets/x-editor/tag12.png');
// 签到天数
const checkInDays = require('@/assets/x-editor/tag13.png');

const getDefaultTags = () => ({
  // 主播昵称
  anchorNick,
  // 粉丝昵称
  userNick,
  // 礼物
  gift,
  // 礼物存量
  walletGift,
  // 奖品
  award,
  // 陪玩技能
  accompanySkill,
  // 贵族等级
  noble,
  // 守护等级
  guardLevel,
  // 粉丝等级
  badge,
  // 马甲格式
  nickPrefix,
  // 陪玩技能
  accompanyTarget,
  // 消费档暗号
  consumeSignal,
  // 签到天数
  checkInDays,
});

export default {
  name: 'XEditor',
  props: {
    value: String, // v-model双向绑定
    mode: String, // 编辑器模式，默认是编辑+展示，可选值：edit，仅编辑
    labels: Array,
  },
  data() {
    return {
      editing: false,
    };
  },
  mounted() {
    // 保存一下dom
    this.paper = this.$el.querySelector('.x-editor-paper');

    // 把字符串转成html，加上占位
    // this.paper.innerHTML = this.text2html(this.value);

    // 失去焦点自动保存
    this.paper.addEventListener('focus', () => {
      this.editing = true;
    });
    // 失去焦点自动保存
    this.paper.addEventListener('blur', () => {
      this.save();
      this.editing = false;
    });
    // this.syncTags();
  },
  computed: {
    taglist() {
      let tags = {};
      if (this.labels && this.labels.length) {
        const DEFAULT_TAGS = getDefaultTags();
        this.labels.forEach((label) => {
          if (Object.prototype.hasOwnProperty.call(DEFAULT_TAGS, label)) {
            tags[label] = DEFAULT_TAGS[label];
          }
        });
      }
      return tags;
    },
    xhtml() {
      return this.text2html(this.value);
    },
  },
  methods: {
    // 插入标签
    insert(name) {
      // 选区操作
      const session = window.getSelection();
      if (!this.editing) {
        this.paper.focus();
        const length = this.paper.childNodes.length;
        if (length) {
          const range = session.getRangeAt(0);
          range.setStart(this.paper, length);
        }
      }
      const label = this.taglist[name];
      if (label) {
        const range = session.getRangeAt(0);
        // 判断是不是光标在paper里
        const image = new Image();
        image.className = 'x-editor-tag x-editor-inline';
        image.setAttribute('data-var', name);
        image.setAttribute('data-label', label);
        image.src = label;
        range.insertNode(image);
        range.setStartAfter(image);
      }
    },

    // 模板转html
    text2html(pattern) {
      const patternTemp = String(pattern || '');
      return patternTemp.replace(/\$\{([^}]+)\}/g, (m, name) => {
        const label = this.taglist[name];
        if (label) {
          return `<img class="x-editor-tag x-editor-inline" data-var="${name}" src="${label}"/>`;
        }
        return m;
      });
    },

    // 取消编辑
    cancel() {
      if (!this.mode) {
        this.editing = false;
        this.paper.innerHTML = this.text2html(this.value);
      }
    },

    // 应用编辑
    save() {
      this.editing = false;
      const dom = this.paper.cloneNode(true);
      dom.querySelectorAll('[data-var]').forEach((node) => {
        const placeholder = node.getAttribute('data-var');
        const text = document.createTextNode(`\${${placeholder}}`);
        node.parentNode.replaceChild(text, node);
      });
      this.$emit('input', dom.textContent);
    },
  },
};
</script>

<style>
.x-editor {
  font-size: 14px;
  position: relative;
  outline: none;
  transition: box-shadow 0.3s ease-out;
  text-align: left;
}
.x-editor-tag {
  display: inline;
}
.x-editor-paper {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  box-sizing: border-box;
  position: relative;
}
.x-editor-paper:hover {
  background: rgba(255, 255, 0, 0.1);
}

.x-editor-paper:focus {
  outline: none;
}
.x-editor-paper::after{
  position: absolute;
  left:5px;
  top:5px;
  right: 70px;
  content:'请输入内容';
  display: block;
  color:#d1d1d1;

}
.x-editor-paper:focus::after{
  display: none;
}
.x-editor-paper.value::after{
  display: none;
}

.x-editor-paper.editing {
  background: rgba(255, 255, 0, 0.15);
  box-shadow: inset 0 -2px 5px 0 rgba(0, 0, 0, 0.1);
}

.x-editor-menu {
  background-color: rgb(84, 92, 100);
  padding: 7px 5px;
  position: absolute;
  bottom: 100%;
  width: 100%;
  font-size: 12px;
  z-index: 2;
  box-sizing: border-box;
}

.x-editor-mode-edit .x-editor-menu {
  position: static;
}

.x-editor-menu:before {
  content: "点击插入➜";
  color: #ccc;
  margin-right: 5px;
}

.x-editor-paper {
  padding: 5px;
  cursor: text;
  min-height: 24px;
  line-height: 24px;
}
.x-editor-tag {
  display: inline-block !important;
  margin: 0 2px;
  background: none;
  border: none;
  height: 18px;
  vertical-align: -4px;
}

.x-editor-menu .x-editor-tag {
  cursor: pointer;
}
</style>
