<template>
  <div class="autoReply">
    <div class="enable-wrap">
      <strong>功能开关</strong>
      <van-switch
        :size="20"
        v-model="enable"
        @change="configChange"
      ></van-switch>
    </div>
    <!--添加关键词-->
    <div class="box-wrap add">
      <div class="box-item-wrap">
        <strong>关键词:</strong>
        <div class="content">
          <div class="tag-wrap">
            <van-button
              plain
              type="info"
              size="small"
              @click="showPop('keywords')"
              ><van-icon name="plus"
            /></van-button>
            <van-tag
              v-for="(word, index) in addOpts.keywords"
              :key="index"
              closeable
              size="medium"
              type="primary"
              @close="delMsgWord(addOpts.keywords, index)"
            >
              {{ word }}
            </van-tag>
          </div>
        </div>
      </div>
      <div class="box-item-wrap">
        <strong>答&nbsp;&nbsp;&nbsp;&nbsp;复:</strong>
        <div class="content">
          <div class="tag-wrap">
            <van-button plain type="info" size="small" @click="showPop('reply')"
              ><van-icon name="plus"
            /></van-button>
            <van-tag
              v-for="(tpl, index) in addOpts.reply"
              :key="index"
              closeable
              size="medium"
              type="primary"
              @close="delTpl(addOpts.reply, index)"
            >
              {{ tpl }}
            </van-tag>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <van-button class="addData" size="small" type="info" @click="addData"
          >添加</van-button
        >
      </div>
    </div>
    <!-- 关键词与答复 -->
    <div class="box-wrap" v-for="(item, index) in config" :key="index">
      <div class="box-item-wrap">
        <strong>关键词:</strong>
        <div class="content">
          <div class="tag-wrap">
            <van-button
              plain
              type="info"
              size="small"
              @click="showPop('keywords', index)"
              ><van-icon name="plus"
            /></van-button>
            <van-tag
              v-for="(word, index) in item.keywords"
              :key="index"
              :closeable="item.keywords.length > 1"
              size="medium"
              type="primary"
              @close="delMsgWord(item.keywords, index, true)"
            >
              {{ word }}
            </van-tag>
          </div>
        </div>
      </div>
      <div class="box-item-wrap">
        <strong>答&nbsp;&nbsp;&nbsp;&nbsp;复:</strong>
        <div class="content">
          <div class="tag-wrap">
            <van-button
              plain
              type="info"
              size="small"
              @click="showPop('reply', index)"
              ><van-icon name="plus"
            /></van-button>
            <van-tag
              v-for="(tpl, iIndex) in item.reply"
              :key="iIndex"
              :closeable="item.reply.length > 1"
              size="medium"
              type="primary"
              @close="delTpl(item.reply, iIndex, true)"
            >
              {{ tpl }}
            </van-tag>
          </div>
        </div>
      </div>
      <!--关闭-->
      <div class="btn-wrap">
        <van-button
          class="addData"
          size="small"
          type="danger"
          @click="removeConfig(index)"
          >移除</van-button
        >
      </div>
    </div>
    <van-popup v-model="show" position="bottom" round>
      <div class="add-btn-wrap">
        <van-field
          v-model.trim="text"
          placeholder="请输入内容"
          type="textarea"
          rows="4"
          clearable
          :maxlength="50"
          show-word-limit
          autofocus
        >
        </van-field>
        <div class="divider"></div>
        <van-button @click="addText" block type="info">确认</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "AutoReply",
  props: {
    source: Object,
  },

  data() {
    return {
      addOpts: {
        reply: [],
        keywords: [],
      },
      config: [],
      enable: false,
      text: "", // 输入的语句
      show: false,
      optIndex: "",
      optType: "", //操作的内容，keywords,reply
    };
  },
  computed: {
    ...mapState(["pid"]),
  },
  watch: {
    pid() {
      if (
        this.source &&
        this.source.params &&
        typeof this.source.params === "object" &&
        this.source.params.length
      ) {
        this.resetData();
        this.config = this.source.params;
        this.enable = this.source.enable;
      }
    },
  },
  created() {
    if (
      this.source &&
      this.source.params &&
      typeof this.source.params === "object" &&
      this.source.params.length
    ) {
      this.config = this.source.params;
      this.enable = this.source.enable;
    }
  },
  methods: {
    ...mapActions(["sendCommandToApp", "setConfig"]),
    resetData() {
      this.addOpts = {
        reply: [],
        keywords: [],
      };
      this.config = [];
      this.enable = false;
      this.show = false;
      this.optIndex = "";
      this.text = "";
      this.optType = "";
    },
    delMsgWord(list, idx, forUpdate = false) {
      list.splice(idx, 1);
      if (forUpdate) {
        this.configChange();
      }
    },
    delTpl(list, index, forUpdate = false) {
      list.splice(index, 1);
      if (forUpdate) {
        this.configChange();
      }
    },
    addData() {
      const { keywords, reply } = this.addOpts;
      if (!keywords.length) {
        this.$notify({
          type: "warning",
          message: "关键字不能空",
        });
        return;
      }
      if (!reply.length) {
        this.$notify({
          type: "warning",
          message: "答复语句不能空",
        });
        return;
      }
      this.addOpts.keywords = [];
      this.addOpts.reply = [];
      this.config.push({
        keywords,
        reply,
      });
      if (this.$refs.replyRef) {
        this.$refs.replyRef.value = "";
      }
      if (this.$refs.keywordRef) {
        this.$refs.keywordRef.value = "";
      }
      this.configChange();
    },
    removeConfig(index) {
      this.config.splice(index, 1);
      this.configChange();
    },
    configChange() {
      this.sendCommandToApp({
        command: "autoReply",
        params: {
          value: this.config,
          id: this.source.id,
          enable: this.enable,
        },
      });
      this.setConfig({
        itemId: this.source.id,
        config: {
          params: this.config,
          enable: this.enable,
        },
      });
    },
    showPop(type, index) {
      if (index !== undefined) {
        this.optIndex = index;
      } else {
        this.optIndex = "";
      }
      this.optType = type;
      this.text = "";
      this.show = true;
    },
    addText() {
      console.log(">>>> add text");
      if (!this.text) {
        this.$toast("内容不能为空");
        return;
      }
      let item = null;
      if (this.optIndex === "") {
        item = this.addOpts;
      } else {
        item = this.config[this.optIndex];
      }
      if (this.optType === "keywords") {
        item = item.keywords;
      } else {
        item = item.reply;
      }
      if (item.includes(this.text)) {
        return this.$notify({
          type: "warning",
          message: "不能重复添加",
        });
      }
      item.push(this.text);
      if (this.optIndex !== "") {
        this.configChange();
      }
      this.text = "";
      this.show = false;
      this.optType = "";
    },
  },
};
</script>

<style lang="scss" scoped src="./index.scss">
</style>