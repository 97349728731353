var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"audio-wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_vm._v("状态")]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"wrap"},[(!_vm.status)?_c('van-icon',{attrs:{"name":"play-circle","color":"#1989fa","size":"30"},on:{"click":_vm.statusChange}}):_c('van-icon',{attrs:{"name":"pause-circle","color":"#1989fa","size":"30"},on:{"click":_vm.statusChange}}),_c('div',{staticClass:"progress-wrap"},[_c('van-progress',{attrs:{"percentage":_vm.percent}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.currentStr)+" / "+_vm._s(_vm.totalStr))]),_c('div',{staticClass:"time-text"},[_vm._v("更新时间："+_vm._s(_vm.earliest))])],1)],1)])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_vm._v("音量")]),_c('div',{staticClass:"value"},[_c('van-slider',{attrs:{"min":0,"max":100},on:{"change":_vm.voiceChange},model:{value:(_vm.voice),callback:function ($$v) {_vm.voice=$$v},expression:"voice"}})],1)]),(_vm.source.type === 'audio')?[_c('div',{staticClass:"title-item"},[_vm._v("资源")]),_c('van-cell-group',{attrs:{"border":false}},_vm._l((_vm.resource),function(sitem,index){return _c('van-cell',{key:sitem.id,attrs:{"data-index":index},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"source-item"},[_c('div',{staticClass:"audio-wrap",class:{
                working:
                  _vm.fileUpStatus[sitem.id] &&
                  ['pending', 'running'].indexOf(_vm.fileUpStatus[sitem.id]) > -1,
              }},[_c('audio',{attrs:{"src":sitem.url,"controls":"","controlsList":"nodownload"}})]),_c('van-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.showUpload(index)}}},[_vm._v("替换")]),_c('div',{staticClass:"time-text"},[_vm._v(" 上传时间："+_vm._s(_vm.timeStr(sitem.updatedAt))+" ")]),_c('div',{staticClass:"time-text remain",class:{
                timeout:
                  Date.now() >
                  new Date(sitem.updatedAt).getTime() + 2 * 3600 * 24 * 1000,
              }},[_vm._v(" "+_vm._s(_vm.remainTime(sitem.updatedAt))+" ")])],1)]},proxy:true}],null,true)})}),1)]:_vm._e(),_c('van-popup',{on:{"close":_vm.closeFileUpload},model:{value:(_vm.showFileUpload),callback:function ($$v) {_vm.showFileUpload=$$v},expression:"showFileUpload"}},[_c('div',{staticClass:"upload-wrap"},[_c('div',{staticClass:"tip"},[_vm._v("请选择需要上传的文件,总时长需要2小时以上")]),_c('div',{staticClass:"upload-content-wrap"},[_c('van-uploader',{attrs:{"multiple":"","after-read":_vm.afterRead,"before-read":_vm.beforeRead,"accept":".mp3,.wav,.m4a"},model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('van-divider',[_vm._v("总时长："+_vm._s(_vm.fileTotalTimeStr))]),_c('div',{staticClass:"upload-btn"},[_c('van-button',{attrs:{"disabled":_vm.disableUpload,"block":"","type":"info"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.upload.apply(null, arguments)}}},[_vm._v("上传文件")])],1)],1)]),_c('van-overlay',{attrs:{"show":_vm.showLoading,"z-index":9000}},[_c('div',{staticClass:"loading-wrapper"},[_c('van-loading',{attrs:{"type":"spinner","color":"#fff"}})],1)]),_c('van-overlay',{attrs:{"show":_vm.showUpLoading,"z-index":9000}},[_c('div',{staticClass:"loading-wrapper"},[_c('van-circle',{attrs:{"rate":_vm.loadRate,"speed":100,"fill":"#fff","text":_vm.loadText},model:{value:(_vm.curRate),callback:function ($$v) {_vm.curRate=$$v},expression:"curRate"}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }