<template>
  <div class="guide">
    <section>
      <div class="setItem">
        <strong>功能开关</strong>
        <div class="setItemView">
          <van-switch
            :size="20"
            v-model="enable"
            @change="configChange"
          ></van-switch>
        </div>
      </div>
      <!-- 发送模式 -->
      <div class="setItem" v-if="false">
        <strong>发送模式</strong>
        <div class="setItemView">
          <van-radio-group
            v-model="config.mode"
            direction="horizontal"
            @change="configChange"
          >
            <van-radio name="default" shape="square">顺序发送</van-radio>
            <van-radio name="random" shape="square">随机发送</van-radio>
          </van-radio-group>
        </div>
      </div>

      <!-- 发送频率 -->
      <div class="setItem">
        <strong>发送频率</strong>
        <p>
          每隔<van-stepper
            v-model="config.interval"
            :decimal-length="1"
            :min="0.2"
            @blur="configChange"
            @minus="minusTime"
            @plus="plusTime"
          />分钟发送一条
        </p>
      </div>

      <!-- 句式池 -->
      <div class="setItem">
        <strong>句式池</strong>
        <div class="addView">
          <Editor v-model.trim="text" mode="edit" :labels="[]"></Editor>
          <button class="isActive" @click="addGuideTpl">添加</button>
        </div>
        <div class="setItemView listWrap">
          <p>随机发送下列句式，可以点击编辑或删除，也可以自定义添加。</p>
          <ul>
            <li v-for="(item, index) in config.text" :key="index">
              <Editor
                v-model="config.text[index]"
                @input="checkEmpty(config.text, index)"
                :labels="[]"
              ></Editor>
              <i
                class="van-icon van-icon-cross"
                @click="removeTpl(config.text, index)"
              ></i>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template> 

<script>
import Editor from "@/components/Editor.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "Guide",
  components: { Editor },
  props: {
    source: Object,
  },

  data() {
    return {
      enable: false,
      config: {
        interval: 1,
        mode: "default",
        text: [],
      },
      text: "",
    };
  },
  created() {
    if (
      this.source &&
      this.source.params &&
      typeof this.source.params === "object"
    ) {
      this.config = this.source.params;
      this.enable = this.source.enable;
      this.config.interval =
        Math.round(this.source.params.interval / 6000) / 10;
    }
  },
  computed: {
    ...mapState(["pid"]),
  },
  watch: {
    pid() {
      if (
        this.source &&
        this.source.params &&
        typeof this.source.params === "object" &&
        this.source.params.length
      ) {
        this.resetData();
        this.config = this.source.params;
        this.enable = this.source.enable;
        this.config.interval =
          Math.round(this.source.params.interval / 6000) / 10;
      }
    },
  },
  methods: {
    ...mapActions(["sendCommandToApp", "setConfig"]),
    resetData() {
      this.enable = false;
      this.config = {
        interval: 1,
        mode: "default",
        text: [],
      };
      this.text = "";
    },
    configChange() {
      let config = { ...this.config };
      if (config.interval < 0.2) {
        config.interval = 0.2;
        this.config.interval = 0.2;
      }
      config.interval = config.interval * 60 * 1000;
      this.sendCommandToApp({
        command: "guide",
        params: {
          value: config,
          id: this.source.id,
          enable: this.enable,
        },
      });
      this.setConfig({
        itemId: this.source.id,
        config: {
          params: config,
          enable: this.enable,
        },
      });
    },
    checkEmpty(list, index) {
      if (!String(list[index]).trim()) {
        this.removeTpl(list, index);
      } else {
        this.configChange();
      }
    },
    removeTpl(list, index) {
      list.splice(index, 1);
      this.configChange();
    },
    addGuideTpl() {
      if (!this.text) {
        return;
      }
      if (this.config.text.includes(this.text)) {
        return this.$notify({
          type: "warning",
          message: "不能重复添加",
        });
      }
      this.config.text.push(this.text);
      this.configChange();
      this.text = "";
    },
    minusTime() {
      this.config.interval--;
      this.configChange();
    },
    plusTime() {
      this.config.interval++;
      this.configChange();
    },
  },
};
</script>

<style lang="scss" scoped src="./index.scss">
</style>